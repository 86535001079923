import React, { useState } from "react";

import Layout from "@components/layout";
import SEO from "@components/seo";
// import Particles from "react-particles-js"
import close from "@images/001-cancel.svg";

import { Fade } from "react-reveal";

// import _ from "lodash"
// Inline styling

const termsContent = {
  padding: "10rem 0 5rem 0",
  position: "relative",
  overflow: "hidden",
};

// end of styling

const teams = [
  {
    id: 1,
    email: "george.bluth@reqres.in",
    first_name: "George",
    last_name: "Bluth",
    avatar: "https://randomuser.me/api/portraits/men/82.jpg",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi officia, vitae laborum aperiam minus id eveniet ullam culpa neque laudantium, est modi maiores, sunt assumenda aliquid consequatur nihil odit expedita.",
    position: "Marketing Director",
  },
  {
    id: 2,
    email: "janet.weaver@reqres.in",
    first_name: "Janet",
    last_name: "Weaver",
    avatar: "https://randomuser.me/api/portraits/women/60.jpg",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Mollitia unde aliquam, ducimus voluptates debitis velit. Optio exercitationem, officiis eveniet adipisci nostrum voluptates dolor, aspernatur cumque asperiores molestiae quo sint pariatur.",
    position: "Marketing Assistant",
  },
  {
    id: 3,
    email: "emma.wong@reqres.in",
    first_name: "Emma",
    last_name: "Wong",
    avatar: "https://randomuser.me/api/portraits/women/87.jpg",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perferendis eum suscipit, asperiores qui officia aut expedita dolor necessitatibus. Aperiam beatae fuga libero nostrum ratione, veritatis maxime, consequatur expedita placeat sint!",
    position: "Digital Marketing",
  },
  {
    id: 4,
    email: "eve.holt@reqres.in",
    first_name: "Eve",
    last_name: "Holt",
    avatar: "https://randomuser.me/api/portraits/women/56.jpg",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi expedita perspiciatis accusantium nam illum tenetur, assumenda quasi aliquam nemo sapiente dicta aspernatur ex veritatis dolorem modi facere reprehenderit eum inventore!",
    position: "Manager",
  },
  {
    id: 5,
    email: "charles.morris@reqres.in",
    first_name: "Charles",
    last_name: "Morris",
    avatar: "https://randomuser.me/api/portraits/men/43.jpg",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta quisquam officia cumque aliquid porro repellendus vel earum eius nobis ea modi delectus cupiditate, accusantium dolorum id veniam obcaecati, voluptatem iusto.",
    position: "Technical Lead",
  },
  {
    id: 6,
    email: "tracey.ramos@reqres.in",
    first_name: "Tracey",
    last_name: "Ramos",
    avatar: "https://randomuser.me/api/portraits/women/10.jpg",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta eveniet ipsum recusandae consectetur similique, amet error quisquam minus cupiditate accusantium explicabo ab aut quaerat fuga at animi minima dolor sunt.",
    position: "HR Manager",
  },
  {
    id: 7,
    email: "michael.lawson@reqres.in",
    first_name: "Michael",
    last_name: "Lawson",
    avatar: "https://randomuser.me/api/portraits/men/74.jpg",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore magni mollitia ducimus odio voluptate atque, incidunt ipsa quod. Autem blanditiis nemo facilis quia, ab voluptas quidem reprehenderit sapiente rerum reiciendis.",
    position: "Sales Consultant",
  },
  {
    id: 8,
    email: "lindsay.ferguson@reqres.in",
    first_name: "Lindsay",
    last_name: "Ferguson",
    avatar: "https://randomuser.me/api/portraits/women/0.jpg",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet explicabo, porro nam nobis rerum ad asperiores delectus suscipit ab dolorum nihil eveniet similique nisi harum blanditiis officia quasi obcaecati enim.",
    position: "Sales",
  },
];

// const params = {
//   particles: {
//     number: {
//       value: 20,
//       density: {
//         enable: false,
//       },
//     },
//     size: {
//       value: 10,
//       random: true,
//       anim: {
//         speed: 4,
//         size_min: 0.3,
//       },
//     },
//     color: {
//       value: "#25ddf7",
//     },
//     line_linked: {
//       enable: false,
//     },
//     move: {
//       random: true,
//       speed: 1,
//       direction: "top",
//       out_mode: "out",
//     },
//   },
//   interactivity: {
//     events: {
//       onhover: {
//         enable: true,
//         mode: "bubble",
//       },
//       onclick: {
//         enable: true,
//         mode: "repulse",
//       },
//     },
//     modes: {
//       bubble: {
//         distance: 250,
//         duration: 2,
//         size: 0,
//         opacity: 0,
//       },
//       repulse: {
//         distance: 400,
//         duration: 4,
//       },
//     },
//   },
// }

const Team = ({ data, location }) => {
  const [setShow] = useState(true);
  const [setHasid] = useState("a-1");
  // const [hasClass, setHasclass] = useState("")

  const handleClick = (e) => {
    console.log(e.target.id);
    setHasid(e.target.id);
    setShow((prevShow) => !prevShow);
    let item = document.querySelectorAll(".item");
    let parent = e.target.parentElement.parentNode;
    item.forEach((item) => {
      item.classList.remove("extended");
      item.lastElementChild.classList.replace("d-block", "d-none");
    });
    parent.classList.add("extended");

    // item.forEach(item => {

    // })
    if (parent.classList.contains("extended")) {
      parent.lastElementChild.classList.replace("d-none", "d-block");
    } else {
      console.log("No");
    }
    // const timer = setTimeout(() => {
    //   if(parent.classList.contains("extended")) {
    //     // console.log(show);
    //   }
    //   else {
    //     // console.log(false)
    //   }
    // }, 1000);
    // return () => clearTimeout(timer);
  };
  const handleClose = (e) => {
    let item = document.querySelectorAll(".item");
    // let parent = e.target.parentElement.parentNode
    item.forEach((item) => {
      item.classList.remove("extended");
      item.lastElementChild.classList.replace("d-block", "d-none");
    });
  };
  // const team = data.allSanityHeadshot.edges
  // const chunkTeams = _.chunk(teams, 1)

  return (
    <>
      <Layout location={location}>
        <SEO title="Team" description="" />

        <div className="team-content" style={termsContent}>
          {/* <Particles
            params={{ ...params }}
            style={{
              width: "100%",
              position: "fixed",
              height: "100%",
              top: 0,
            }}
          /> */}
          <div className="container px-5 px-md-3">
            <h1 className="text-white text-center pb-5 mb-5">Our Team</h1>

            <ul id="all-teams" className="list-unstyled">
              {teams.map((item, id) => (
                <>
                  <li key={item.id} className={`item d-inline-block mb-4`}>
                    <div className="item-content">
                      <button
                        id={`btn-${item.id}`}
                        className="btn-item border-0 p-0 text-left"
                        onClick={handleClick}
                        aria-label={item.first_name}
                      />
                      <img
                        id={`a-${item.id}`}
                        className="img-fluid"
                        src={item.avatar}
                        alt={item.first_name}
                      />
                      <div className="team-short-info p-4">
                        <h3 className="mb-0 text-primary-gradient">
                          {item.first_name}
                        </h3>
                        <h6 className="mb-0 text-muted">{item.last_name}</h6>
                      </div>
                    </div>
                    <div id={`prev_${item.id}`} className="d-none">
                      <div className="container">
                        <button className="close" onClick={handleClose}>
                          <img className="img-fluid" src={close} alt="close" />
                        </button>
                        <div className="content">
                          <div className="row">
                            <div className="col-md-auto d-none d-md-inline">
                              <Fade>
                                <div className="image-masked">
                                  <img
                                    src={item.avatar}
                                    alt=""
                                    className="img-fluid rounded-circle"
                                  />
                                </div>
                              </Fade>
                            </div>
                            <div className="col-md-7">
                              <div className="bio pl-0 pl-md-4">
                                <Fade bottom>
                                  <h2 className="text-primary-gradient">
                                    {item.first_name} {item.last_name}
                                  </h2>
                                  <p className="mb-0">
                                    <span className="text-secondary">
                                      Position
                                    </span>
                                    : {item.position}
                                  </p>
                                  <p>
                                    <span className="text-secondary">
                                      Email
                                    </span>
                                    : {item.email}
                                  </p>
                                  <hr />
                                  <h5 className="text-secondary mt-4 mb-3">
                                    Short Description
                                  </h5>
                                  <p>{item.description}</p>
                                </Fade>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </>
              ))}
            </ul>
          </div>
        </div>
      </Layout>
    </>
  );
};

// const PreviewTeam = () => {
//   return (
//     <div>
//       <h2>Preview here</h2>
//     </div>
//   )
// }

export default Team;

export const teamPageQuery = graphql`
  query teamPageQuery {
    allSanityHeadshot {
      edges {
        node {
          id
          name
          position
          company
          description
          image {
            alt
            asset {
              id
              fixed(width: 250, height: 250) {
                src
              }
            }
          }
        }
      }
    }
  }
`;
